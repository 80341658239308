<template>
  <GoogleMap
    :api-key="googleMapKey"
    style="width: 100%; height: 500px"
    :center="center"
    :zoom="11"
    :fullscreen-control="!isMarkersDraggable"
    :street-view-control="!isMarkersDraggable"
    :map-type-control="!isMarkersDraggable"
    @mounted="onMapMounted"
  >
    <template v-if="!isMarkersDraggable">
      <CustomMarker
        v-for="(marker, index) in markers"
        :key="`custom-marker-${index}`"
        :options="{
          position: markerLocationFilter(marker),
          anchorPoint: 'BOTTOM_CENTER',
        }"
      >
        <div v-if="variant === 'v1' && !marker.version" style="text-align: center" :style="markerColorFilter(marker)">
          <img
            :src="markerImg"
            width="100%"
            height="100%"
            alt=""
            style="margin-top: 8px; max-width: 76px; max-height: 76px"
          />
        </div>

        <div v-else-if="variant === 'v2' && !marker.version" style="text-align: center">
          <div
            class="tw-flex tw-w-[230px] tw-max-w-[280px] tw-flex-col tw-gap-1 tw-border tw-bg-white tw-px-3 tw-py-4 tw-text-left"
            style="border-color: var(--purple)"
          >
            <library-text :text="marker.name" type="bold" class="!tw-text-xl" />
            <nuxt-link :to="`tel:${marker.phone}`">
              <library-text
                type="bold"
                :text="marker.phone"
                class="tw-text-base"
                style="color: var(--nc-mini-title-color)"
              />
            </nuxt-link>
            <nuxt-link
              :to="marker.button"
              :external="true"
              target="_blank"
              class="tw-pt-2 tw-text-sm"
              :class="{
                'pxp-search-properties-btn': marker.buttonType === 'primary',
                'pxp-primary-cta pxp-animate after:tw-border-inherit': marker.buttonType === 'secondary',
              }"
              :style="`background-color: var(--${
                marker.buttonBackgroundColor
              }); color: var(--${marker.buttonTextColor}); ${
                marker.buttonType === 'secondary' ? `border-top-color: var(--${marker.buttonTextColor});` : ''
              }`"
            >
              {{ marker.buttonLabel }}
            </nuxt-link>
          </div>
        </div>

        <div
          v-else-if="marker.version === 'v3'"
          style="position: relative; display: inline-block"
          @click="toggleCard"
          @mouseover="showCard = true"
          @mouseleave="showCard = false"
        >
          <!-- Marker Görseli -->
          <div>
            <img
              :src="marker.marker"
              width="100%"
              height="100%"
              alt=""
              style="margin-top: 8px; max-width: 76px; max-height: 76px"
            />
          </div>

          <!-- Card -->
          <div
            v-if="showCard"
            class="tw-absolute tw-left-1/2 tw-top-full tw-z-20 -tw-translate-x-1/2 tw-px-4 tw-py-4 tw-text-center"
            style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
          >
            <h6>{{ marker.branchName }}</h6>
            <button @click.stop="openGoogleMaps(marker.lat, marker.lng)" class="n-secondary">View on Google Map</button>
          </div>

          <div
            v-if="showCard"
            @click="closeCard"
            style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; background: transparent"
          ></div>
        </div>
      </CustomMarker>
    </template>
    <template v-else-if="isMarkersDraggable">
      <Marker
        v-for="(marker, index) in markers"
        :key="`marker-${index}`"
        :options="{
          position: marker,
          anchorPoint: 'BOTTOM_CENTER',
          draggable: true,
        }"
        @dragend="onMarkerDragEnd"
      />
    </template>
  </GoogleMap>
</template>

<script lang="ts">
import { GoogleMap, Marker, CustomMarker } from 'vue3-google-map';
import type { PropType } from 'vue';
import { convertToFilter } from '~/units/core/providers/colors.provider';
import { debounce } from '~/units/core/providers/debounce.provider';
import { SubComponentMixin } from '~/mixins/subComponent.mixin';
import type { GoogleMapLocation } from '~/units/addresses/types';

/** draggable markers support was not implemented yet to use draggable maker, please use neuron google map component. **/

interface GoogleMapLocationWithSettings extends GoogleMapLocation {
  color: string;
}

export default defineNuxtComponent({
  name: 'NeuronGoogleMap',
  components: { GoogleMap, Marker, CustomMarker },

  props: {
    center: {
      required: true,
      type: Object as PropType<GoogleMapLocation>,
    },

    markers: {
      required: true,
      type: Array as PropType<GoogleMapLocationWithSettings[]>,
    },

    markerImg: {
      required: false,
      type: String,
      default: '/cdn/content/map-marker-alt.svg',
    },

    isMarkersDraggable: {
      required: false,
      type: Boolean,
      default: false,
    },

    variant: {
      required: false,
      type: String,
      default: 'v1',
    },
  },

  mixins: [SubComponentMixin],

  data() {
    return {
      handler: null,
      showCard: false,
    };
  },

  computed: {
    markersModel: {
      get() {
        return this.markers;
      },
      set(value: GoogleMapLocation) {
        this.$emit('update:markers', value);
      },
    },

    googleMapKey() {
      const nuxtApp = useNuxtApp();
      return nuxtApp.$config.public.GOOGLE_MAPS_KEY;
    },
  },

  mounted() {
    this.createHandler();
  },

  methods: {
    onMapMounted() {},

    createHandler() {
      if (!this.handler && this.isMarkersDraggable) {
        this.handler = debounce(this.onPositionChanged, 500);
      }
    },

    onPositionChanged() {
      if (this.markers && this.isMarkersDraggable) {
        this.$emit('onPositionChanged');
      }
    },

    onMarkerDragEnd(e: any) {
      if (this.isMarkersDraggable) {
        const newPosition = { ...e.latLng };

        this.markersModel = {
          lat: newPosition.lat(),
          lng: newPosition.lng(),
        };

        this.handler();
      }
    },

    toggleCard() {
      this.showCard = !this.showCard;
    },
    closeCard() {
      this.showCard = false;
    },
    openGoogleMaps(lat, lng) {
      const url = `https://www.google.com/maps?q=${lat},${lng}`;
      window.open(url, '_blank');
    },

    markerColorFilter(marker: GoogleMapLocationWithSettings) {
      if (marker.color) {
        return {
          '--primary-color-filter': `${convertToFilter(marker.color)}`,
        };
      }
    },

    markerLocationFilter(marker: any) {
      return {
        lat: marker.lat,
        lng: marker.lng,
      };
    },

    fitMarkersInView() {
      if (!this.map || !this.markers || this.markers.length === 0) return;

      const bounds = new google.maps.LatLngBounds();

      this.markers.forEach((marker) => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
      });

      // Padding değerlerini artırıyoruz
      const padding = {
        top: 100, // Üst boşluğu azalttık
        right: 100, // Sağ boşluğu artırdık
        bottom: 100, // Alt boşluğu artırdık
        left: 100, // Sol boşluğu artırdık
      };

      // Bounds'u padding ile birlikte uygula
      this.map.fitBounds(bounds, padding);

      // Zoom seviyelerini ayarla
      this.$nextTick(() => {
        const zoom = this.map.getZoom();

        if (this.markers.length === 1) {
          // Tek marker varsa daha yakın zoom
          this.map.setZoom(13);
        } else {
          // Birden fazla marker için zoom sınırları
          if (zoom > 13) {
            this.map.setZoom(13);
          }
          if (zoom < 3) {
            this.map.setZoom(3);
          }
        }

        // Markerların ortasını bul
        const center = bounds.getCenter();

        // Haritayı biraz daha yukarı kaydır (offset değerini azalttık)
        const lat = center.lat() + zoom * 0.00005;
        this.map.setCenter({ lat, lng: center.lng() });
      });
    },
  },
});
</script>

<style scoped>
:deep([src='/cdn/content/map-marker-alt.svg']) {
  filter: var(--primary-color-filter);
}
</style>
