<template>
  <div
    :class="`guide-card shine-effect tw-group tw-relative tw-flex tw-h-full tw-w-full tw-cursor-pointer tw-flex-col tw-justify-start tw-gap-5 tw-text-start`"
  >
    <!-- Content for button_link case -->
    <template v-if="guide.button_link">
      <div class="tw-relative">
        <LibraryImage
          :src="guide.photo"
          :h-ratio="1.4"
          :w-ratio="1"
          class="tw-overflow-hidden tw-rounded-[var(--rounded)]"
        />
        <div
          class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
          style="border-radius: var(--rounded)"
        ></div>
        <div
          class="tw-pointer-events-none tw-absolute tw-bottom-3 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
        >
          <p :class="`n-${guide.button_type} !tw-font-bold`" style="color: #fff !important">
            {{ guide.button_label }}
          </p>
        </div>
      </div>
    </template>

    <!-- Content for guide case -->
    <template v-else-if="guide.guide">
      <div class="tw-relative">
        <LibraryImage
          :src="guide.photo"
          :h-ratio="1.4"
          :w-ratio="1"
          class="tw-overflow-hidden tw-rounded-[var(--rounded)]"
          @click="$emit('open-guide-popup')"
        />
        <div
          class="shine tw-absolute tw-inset-0 tw-bg-black tw-opacity-0 tw-transition-opacity tw-duration-500 group-hover:tw-opacity-50"
          style="border-radius: var(--rounded)"
        ></div>
        <div
          class="tw-pointer-events-none tw-absolute tw-bottom-3 tw-left-0 tw-right-0 tw-z-20 tw-flex tw-translate-y-full tw-transform tw-flex-col tw-items-center tw-gap-5 tw-overflow-hidden tw-p-4 tw-text-center tw-opacity-0 tw-transition-all tw-duration-500 group-hover:tw-pointer-events-auto group-hover:tw-translate-y-0 group-hover:tw-opacity-100"
        >
          <p v-if="guide.guide" :class="`n-${guide.button_type} !tw-font-bold`" style="color: #fff !important">
            {{ guide.button_label }}
          </p>
        </div>
      </div>
    </template>

    <!-- Common title section -->
    <div class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start tw-gap-4 tw-text-start">
      <div>
        <h6 class="tw-line-clamp-3">{{ guide.title }}</h6>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme6GuideCard',

  mixins: [ComponentMixin],

  props: {
    guide: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    version: {
      type: String,
      default: 'v1',
    },
  },

  emits: ['open-guide-popup', 'click'],
});
</script>

<style scoped>
.guide-card {
  transition: all 0.3s;
}

.guide-card:hover {
  transform: scale(0.98);
}
</style>
