<template>
  <div @click="goBackToPropertyList()" class="n-link tw-flex tw-cursor-pointer tw-items-center" style="z-index: 30">
    <div class="tw-mr-2">
      <svg width="28" height="17" viewBox="0 0 28 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.875 9.375H6.125C5.635 9.375 5.25 8.99 5.25 8.5C5.25 8.01 5.635 7.625 6.125 7.625H21.875C22.365 7.625 22.75 8.01 22.75 8.5C22.75 8.99 22.365 9.375 21.875 9.375Z"
          :fill="color"
        />
        <path
          d="M10.5 15.5C10.3852 15.5014 10.2715 15.4788 10.166 15.4336C10.0605 15.3883 9.96563 15.3216 9.8875 15.2375L3.7625 9.1125C3.4125 8.7625 3.4125 8.22 3.7625 7.87L9.8875 1.7625C10.2375 1.4125 10.78 1.4125 11.13 1.7625C11.48 2.1125 11.48 2.655 11.13 3.005L5.6175 8.5175L11.13 14.03C11.48 14.38 11.48 14.9225 11.13 15.2725C10.955 15.4475 10.7275 15.535 10.5175 15.535L10.5 15.5Z"
          :fill="color"
        />
      </svg>
    </div>
    <h6 :style="`color: ${color}`">{{ text }}</h6>
  </div>
</template>

<script lang="ts">
import { mapActions } from 'pinia';
import { usePropertiesStore } from '~/units/properties/stores';

export default defineNuxtComponent({
  name: 'BackToAllPropertiesNavbar',

  props: {
    text: {
      required: false,
      default: 'Back to All Properties',
      type: String,
    },

    color: {
      required: false,
      type: String,
      default: 'var(--c-primary)',
    },
  },

  methods: {
    ...mapActions(usePropertiesStore, ['goBackToPropertyList']),
  },
});
</script>
