<template>
  <div v-if="excludedThemes.includes(theme)" :style="backgroundColor">
    <library-container :style="backgroundColor" style="width: 100%">
      <div id="wrapper" class="tw-py-20">
        <div class="g-0 tw-flex tw-grid tw-w-full tw-grid-cols-12 tw-justify-center tw-px-4 xl:tw-p-5">
          <div class="tw-col-span-12">
            <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center">
              <lazy-library-title
                tag="h2"
                v-if="variables.boxTitleText"
                :text="variables.boxTitleText"
                class="tw-mb-10"
              ></lazy-library-title>
              <div class="tw-flex tw-w-full tw-justify-center">
                <div class="tw-mt-3 tw-grid tw-w-full tw-grid-cols-9 tw-gap-y-3 xl:tw-gap-y-0">
                  <div class="tw-col-span-12 xl:tw-col-span-2">
                    <div class="tw-grid tw-grid-cols-12 tw-border-r tw-border-[var(--default-border-color)]">
                      <div
                        class="tw-col-span-6 tw-rounded-sm xl:tw-col-span-6 2xl:tw-col-span-6"
                        style="background-color: var(--white)"
                      >
                        <div
                          class="py-3 px-4 text-center text-weight-light n-cursor-pointer m-1 tw-rounded-sm tw-uppercase"
                          :class="searchType === PFilterSearchType.SALES ? 'm-1' : ''"
                          :style="searchType === PFilterSearchType.SALES ? selectedBoxColorStyle : ''"
                          @click="onSearchTypeClicked(PFilterSearchType.SALES)"
                        >
                          <lazy-library-text :text="variables.buyOptionText"></lazy-library-text>
                        </div>
                      </div>
                      <div
                        class="tw-col-span-6 tw-rounded-sm xl:tw-col-span-6 2xl:tw-col-span-6"
                        style="background-color: var(--white)"
                      >
                        <div
                          class="py-3 px-4 text-center text-weight-light n-cursor-pointer m-1 tw-rounded-sm tw-uppercase"
                          :class="searchType === PFilterSearchType.LETTINGS ? 'm-1' : ''"
                          :style="searchType === PFilterSearchType.LETTINGS ? selectedBoxColorStyle : ''"
                          @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
                        >
                          <lazy-library-text :text="variables.rentOptionText"></lazy-library-text>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tw-col-span-12 tw-flex tw-h-[60px] tw-content-start tw-items-center xl:tw-col-span-6 xl:tw-h-full"
                    style="background-color: var(--white); border-right: 1px solid var(--default-border-color)"
                  >
                    <locations
                      v-model="searchInput"
                      v-model:locations="locations"
                      :is-mile-selection-visible="false"
                      :is-search-icon-hidden="true"
                      class="tw-h-full"
                    />
                  </div>
                  <div class="tw-col-span-12 xl:tw-col-span-1" :style="buttonColorsStyle">
                    <nc-button :label="variables.searchButtonText" :height="64" @on-click="onSearched()">
                      <template #label>
                        <div class="nc-secondary-button tw-flex tw-flex-row tw-items-center tw-gap-x-2">
                          <span class="nc-secondary-button__text tw-uppercase">Search</span>
                          <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path
                              class="nc-secondary-button__icon"
                              d="M17.4995 15.8333L13.2045 11.5383C13.9985 10.3316 14.3165 8.87314 14.097 7.44537C13.8774 6.01761 13.1359 4.72208 12.016 3.80963C10.8961 2.89718 9.47752 2.43271 8.03484 2.50614C6.59216 2.57958 5.22805 3.1857 4.2066 4.20715C3.18515 5.22859 2.57903 6.59271 2.50559 8.03539C2.43216 9.47807 2.89663 10.8967 3.80908 12.0166C4.72154 13.1365 6.01706 13.878 7.44483 14.0975C8.87259 14.317 10.331 13.999 11.5378 13.205L15.8328 17.5L17.4995 15.8333ZM4.16612 8.33333C4.16612 6.03584 6.03529 4.16667 8.33279 4.16667C10.6303 4.16667 12.4995 6.03584 12.4995 8.33333C12.4995 10.6308 10.6303 12.5 8.33279 12.5C6.03529 12.5 4.16612 10.6308 4.16612 8.33333Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </template>
                    </nc-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </library-container>
  </div>
  <!-- THEME 4... -->

  <section
    v-else-if="!excludedThemes.includes(theme) && variant === variants.v1"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="on-the-fly-search-box tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-8">
      <div v-if="vars.titleHtml" v-html="vars.titleHtml" style="color: var(--c-text-secondary)"></div>
      <h3 v-else>{{ vars.titleText }}</h3>
      <div
        v-if="!vars.horizontalBool && (vars.buyOptionText || vars.rentOptionText)"
        class="tw-flex tw-gap-1 max-lg:tw-w-full max-lg:tw-rounded-xl max-lg:tw-border-2 max-lg:tw-border-[#ccc] max-lg:tw-p-[1px] lg:tw-gap-4"
      >
        <button
          v-if="vars.buyOptionText"
          class="select-button"
          :class="{ active: searchType === PFilterSearchType.SALES }"
          @click="onSearchTypeClicked(PFilterSearchType.SALES)"
        >
          {{ vars.buyOptionText }}
        </button>

        <button
          v-if="vars.rentOptionText"
          class="select-button max-lg:tw-border-none"
          :class="{ active: searchType === PFilterSearchType.LETTINGS }"
          @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
        >
          {{ vars.rentOptionText }}
        </button>
      </div>
      <div
        class="tw-relative tw-flex tw-w-full max-lg:tw-flex-col max-lg:tw-gap-7"
        :style="`border-radius: var(--rounded);`"
      >
        <div
          v-if="vars.horizontalBool"
          class="searchbox-option tw-flex tw-border-r tw-bg-white max-lg:tw-w-full max-lg:tw-border-[#ccc] max-lg:tw-p-[1px] max-md:tw-border lg:tw-p-[2px]"
        >
          <button
            v-if="vars.buyOptionText"
            class="select-button lg:!tw-rounded-none"
            :class="{ active: searchType === PFilterSearchType.SALES }"
            @click="onSearchTypeClicked(PFilterSearchType.SALES)"
          >
            {{ vars.buyOptionText }}
          </button>

          <button
            v-if="vars.rentOptionText"
            class="select-button lg:!tw-rounded-none"
            :class="{ active: searchType === PFilterSearchType.LETTINGS }"
            @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
          >
            {{ vars.rentOptionText }}
          </button>
        </div>
        <locations
          v-model="searchInput"
          v-model:locations="locations"
          :is-mile-selection-visible="false"
          :is-search-icon-hidden="true"
          version="v2"
          :horizontal-layout="vars.horizontalBool"
        />
        <button
          @click="onSearched()"
          class="search-box-btn n-primary tw-h-full lg:tw-absolute lg:tw-right-0 lg:tw-top-0"
          :class="{
            'tw-flex tw-items-center tw-justify-center tw-gap-1': vars.isSearchIconBool,
          }"
        >
          <svg
            v-if="vars.isSearchIconBool"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4995 15.8333L13.2045 11.5383C13.9985 10.3316 14.3165 8.87314 14.097 7.44537C13.8774 6.01761 13.1359 4.72208 12.016 3.80963C10.8961 2.89718 9.47752 2.43271 8.03484 2.50614C6.59216 2.57958 5.22805 3.1857 4.2066 4.20715C3.18515 5.22859 2.57903 6.59271 2.50559 8.03539C2.43216 9.47807 2.89663 10.8967 3.80908 12.0166C4.72154 13.1365 6.01706 13.878 7.44483 14.0975C8.87259 14.317 10.331 13.999 11.5378 13.205L15.8328 17.5L17.4995 15.8333ZM4.16612 8.33333C4.16612 6.03584 6.03529 4.16667 8.33279 4.16667C10.6303 4.16667 12.4995 6.03584 12.4995 8.33333C12.4995 10.6308 10.6303 12.5 8.33279 12.5C6.03529 12.5 4.16612 10.6308 4.16612 8.33333Z"
            />
          </svg>
          {{ vars.searchButtonText }}
        </button>
      </div>
    </div>
  </section>
  <!-- version 6  -->
  <section
    v-else-if="!excludedThemes.includes(theme) && variant === variants.v2"
    class="n-section-secondary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="on-the-fly-search-box tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-6">
      <div>
        <p v-if="vars.subTitleText" class="lg:tw-text-center">{{ vars.subTitleText }}</p>
        <h3>{{ vars.titleText }}</h3>
        <div v-if="vars.titleHtml" v-html="vars.titleHtml" />
      </div>
      <div
        v-if="!vars.horizontalBool && (vars.buyOptionText || vars.rentOptionText)"
        class="search-button-container tw-flex tw-gap-1 max-lg:tw-w-full max-lg:tw-rounded-xl max-lg:tw-border-2 max-lg:tw-border-[#ccc] max-lg:tw-p-[1px] lg:tw-gap-4"
      >
        <button
          v-if="vars.buyOptionText"
          class="select-button"
          :class="{ active: searchType === PFilterSearchType.SALES }"
          @click="onSearchTypeClicked(PFilterSearchType.SALES)"
        >
          {{ vars.buyOptionText }}
        </button>

        <button
          v-if="vars.rentOptionText"
          class="select-button max-lg:tw-border-none"
          :class="{ active: searchType === PFilterSearchType.LETTINGS }"
          @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
        >
          {{ vars.rentOptionText }}
        </button>
      </div>
      <div
        class="property-searchbox-container tw-relative tw-flex tw-w-full tw-gap-4 max-lg:tw-flex-col max-lg:tw-gap-7"
        :style="`border-radius: var(--rounded);`"
      >
        <div
          v-if="vars.horizontalBool && vars.buyOptionText && vars.rentOptionText"
          class="searchbox-option tw-flex tw-w-full tw-border max-lg:tw-w-full max-lg:tw-p-[1px] max-md:tw-border lg:tw-max-w-[224px] lg:tw-p-[2px]"
          style="border-radius: var(--rounded); border-color: var(--c-border)"
        >
          <button
            v-if="vars.buyOptionText"
            class="select-button"
            :class="{ active: searchType === PFilterSearchType.SALES }"
            @click="onSearchTypeClicked(PFilterSearchType.SALES)"
          >
            {{ vars.buyOptionText }}
          </button>

          <button
            v-if="vars.rentOptionText"
            class="select-button"
            :class="{ active: searchType === PFilterSearchType.LETTINGS }"
            @click="onSearchTypeClicked(PFilterSearchType.LETTINGS)"
          >
            {{ vars.rentOptionText }}
          </button>
        </div>
        <locations
          v-model="searchInput"
          v-model:locations="locations"
          :is-mile-selection-visible="false"
          :is-search-icon-hidden="true"
          version="v2"
          :horizontal-layout="vars.horizontalBool"
        />
        <button
          @click="onSearched()"
          class="search-box-btn n-primary tw-h-full lg:tw-absolute lg:tw-right-0 lg:tw-top-0"
          :class="{
            'tw-flex tw-items-center tw-justify-center tw-gap-1': vars.isSearchIconBool,
          }"
        >
          <svg
            v-if="vars.isSearchIconBool"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4995 15.8333L13.2045 11.5383C13.9985 10.3316 14.3165 8.87314 14.097 7.44537C13.8774 6.01761 13.1359 4.72208 12.016 3.80963C10.8961 2.89718 9.47752 2.43271 8.03484 2.50614C6.59216 2.57958 5.22805 3.1857 4.2066 4.20715C3.18515 5.22859 2.57903 6.59271 2.50559 8.03539C2.43216 9.47807 2.89663 10.8967 3.80908 12.0166C4.72154 13.1365 6.01706 13.878 7.44483 14.0975C8.87259 14.317 10.331 13.999 11.5378 13.205L15.8328 17.5L17.4995 15.8333ZM4.16612 8.33333C4.16612 6.03584 6.03529 4.16667 8.33279 4.16667C10.6303 4.16667 12.4995 6.03584 12.4995 8.33333C12.4995 10.6308 10.6303 12.5 8.33279 12.5C6.03529 12.5 4.16612 10.6308 4.16612 8.33333Z"
            />
          </svg>
          {{ vars.searchButtonText }}
        </button>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import Locations from '~/components/common/Locations.vue';
import { useWebsiteStore } from '~/units/website/store';
import { onTheFlySearchBox } from '~/units/properties/mixins/onTheFlySearchBox.mixin';

export default defineNuxtComponent({
  name: 'LibraryOnTheFlyPropertySearchBox',
  components: { Locations, NcButton },

  mixins: [ComponentMixin, onTheFlySearchBox],

  data() {
    return {
      excludedThemes: ['theme2', 'theme3'],
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['theme']),

    buttonColorsStyle() {
      let text = '';

      if (this.vars.searchButtonCustomBgColor) {
        text += `--nc-primary-button-bg-color: var(--${this.vars.searchButtonCustomBgColor});`;
      }

      if (this.vars.searchButtonCustomTextColor) {
        text += `--nc-primary-button-text-color: var(--${this.vars.searchButtonCustomTextColor});`;
      }

      if (this.theme === 'theme3') {
        text += `--nc-primary-button-radius: 0;`;
      }

      return text;
    },

    buttonTextColor() {
      if (this.vars.buttonTextColor) {
        return this.vars.buttonTextColor;
      }

      return 'var(--white)';
    },

    backgroundColor() {
      if (this.theme === 'theme2') {
        return 'background-color: var(--seashell);';
      } else if (this.theme === 'theme3') {
        return `background-color: var(--${this.vars.backgroundColor})`;
      }
      return '';
    },

    selectedBoxColorStyle() {
      if (this.theme === 'theme2') {
        return 'background-color: var(--brown); color: var(--white);';
      } else if (this.theme === 'theme3') {
        return `background-color: var(--purple); color: ${this.buttonTextColor};`;
      }
      return '';
    },

    overlayTitle() {
      return getVariable(this.component, 'overlay_title-html');
    },

    variables() {
      return {
        buyOptionText: getVariable(this.component, 'buy_option-text'),
        rentOptionText: getVariable(this.component, 'rent_option-text'),
        searchBoxPlaceholderText: getVariable(this.component, 'searchbox_placeholder-text'),
        searchButtonText: getVariable(this.component, 'search_button-text'),
        boxTitleText: getVariable(this.component, 'box_title-text'),
      };
    },
  },
});
</script>

<style scoped>
#wrapper {
  position: relative;
}

.nc-secondary-button .nc-secondary-button__text {
  color: var(--nc-primary-button-text-color) !important;
}

.nc-secondary-button .nc-secondary-button__icon {
  fill: var(--nc-primary-button-text-color) !important;
}

.nc-secondary-button:hover .nc-secondary-button__icon {
  fill: var(--nc-secondary-button-hover-color) !important;
}

.nc-secondary-button:hover .nc-secondary-button__text {
  color: var(--nc-secondary-button-hover-color) !important;
}

::v-deep(.live-search-container) {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .searchbox-option {
    border-radius: var(--rounded);
  }
}

::v-deep(.properties-search-locations) {
  border-radius: var(--rounded);
}
</style>
